import { render, staticRenderFns } from "./PageTitle.vue?vue&type=template&id=73e58588&"
import script from "./PageTitle.vue?vue&type=script&lang=js&"
export * from "./PageTitle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeading: require('/opt/build/repo/components/base/BaseHeading.vue').default,BaseText: require('/opt/build/repo/components/base/BaseText.vue').default})
