//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Feature',
  props: {
    feature: {
      type: Object,
      required: true,
    },
  },
}
