//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('global', [
      'pageHasModalOpen',
      'isMobileMenuOpen',
      'mainNav',
      'logo',
      'footer',
      'contact',
    ]),
    hasOpenMenu() {
      return this.pageHasModalOpen || this.isMobileMenuOpen
    },
    isHomePage() {
      return this.$route.fullPath === '/'
    },
    lockScroll() {
      return this.pageHasModalOpen || this.isMobileMenuOpen
    },
  },
}
