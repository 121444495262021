//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

import FooterCallToAction from '~/components/blocks/FooterCallToAction'

export default {
  components: {
    FooterCallToAction,
  },
  props: {
    footerContent: {
      type: Object,
      required: true,
    },
    contact: {
      type: Array,
      required: true,
    },
  },
}
