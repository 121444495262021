//
//
//
//
//
//
//
//
//
//
//

import Feature from '~/components/atoms/Feature'

export default {
  name: 'KeyFeatures',
  components: {
    Feature,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
}
