//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headingSize() {
      return this.block.headingSize === 'large'
        ? 'text-2xl lg:text-4xl lg:max-w-2xl  mb-12'
        : 'text-lg mb-8'
    },
  },
}
