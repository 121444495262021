import { render, staticRenderFns } from "./PartnerBio.vue?vue&type=template&id=78087338&scoped=true&"
import script from "./PartnerBio.vue?vue&type=script&lang=js&"
export * from "./PartnerBio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78087338",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/opt/build/repo/components/base/BaseImage.vue').default,BaseHeading: require('/opt/build/repo/components/base/BaseHeading.vue').default,BaseRichText: require('/opt/build/repo/components/base/BaseRichText/BaseRichText.vue').default})
