import { render, staticRenderFns } from "./TwoColImageRight.vue?vue&type=template&id=662365d1&scoped=true&"
import script from "./TwoColImageRight.vue?vue&type=script&lang=js&"
export * from "./TwoColImageRight.vue?vue&type=script&lang=js&"
import style0 from "./TwoColImageRight.vue?vue&type=style&index=0&id=662365d1&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "662365d1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/opt/build/repo/components/base/BaseImage.vue').default,BaseHeading: require('/opt/build/repo/components/base/BaseHeading.vue').default,BaseRichText: require('/opt/build/repo/components/base/BaseRichText/BaseRichText.vue').default,BaseButton: require('/opt/build/repo/components/base/BaseButton.vue').default,BaseTwoCol: require('/opt/build/repo/components/base/BaseTwoCol.vue').default})
