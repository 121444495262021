import { render, staticRenderFns } from "./TwoColRichText.vue?vue&type=template&id=725db8ed&"
import script from "./TwoColRichText.vue?vue&type=script&lang=js&"
export * from "./TwoColRichText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseRichText: require('/opt/build/repo/components/base/BaseRichText/BaseRichText.vue').default,BaseTwoCol: require('/opt/build/repo/components/base/BaseTwoCol.vue').default})
