import { render, staticRenderFns } from "./TwoColImageLeft.vue?vue&type=template&id=a0e2909e&scoped=true&"
import script from "./TwoColImageLeft.vue?vue&type=script&lang=js&"
export * from "./TwoColImageLeft.vue?vue&type=script&lang=js&"
import style0 from "./TwoColImageLeft.vue?vue&type=style&index=0&id=a0e2909e&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0e2909e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/opt/build/repo/components/base/BaseImage.vue').default,BaseHeading: require('/opt/build/repo/components/base/BaseHeading.vue').default,BaseRichText: require('/opt/build/repo/components/base/BaseRichText/BaseRichText.vue').default,BaseTwoCol: require('/opt/build/repo/components/base/BaseTwoCol.vue').default})
