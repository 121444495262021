//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { Portal } from 'portal-vue'

export default {
  components: {
    Portal,
  },
  props: {
    nav: {
      type: Array,
      required: true,
    },
    logo: {
      type: Object,
      required: true,
      default: () => {},
    },
    contact: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('global', ['isMobileMenuOpen', 'pageHasModalOpen']),
    isHomePage() {
      return this.$route.fullPath === '/'
    },
  },
  watch: {
    $route(to, from) {
      this.closeMenu()
    },
  },
  methods: {
    async openMobileMenu() {
      await this.$store.commit('global/isMobileMenuOpen', true)
      await this.$nextTick()
      await this.$nextTick()

      this.$refs.closeButtonRef?.focus()
    },
    async closeMenu(e) {
      await this.$store.commit('global/isMobileMenuOpen', false)
      await this.$nextTick()
      await this.$nextTick()
    },
  },
}
